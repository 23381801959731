<template>
  <div class="profile">
    <Header />
    <HeaderBottomProfile />
    <b-container class="profile-container">
      <div v-if="user?.individual != null">
        <div v-if="user?.individual?.investors_risk_profile == null && user?.individual?.is_fbonds !== 1">
          <CompleteTheProfile />
        </div>
      </div>
      <b-row class="justify-content-md-center">
        <div class="col-lg-8 col-md-6">
          <div>
            <b-card class="product-performance mb-5">
              <div class="d-none d-lg-block">
                <b-row>
                  <div class="profile-tab">
                    <b-row class="justify-content-center">
                      <div class="myprofile">
                        <b-col>
                          <h3><b-link to="/profile">Profil</b-link></h3>
                        </b-col>
                      </div>
                      <div class="profile-identity">
                        <b-col>
                          <h3>
                            <b-link href="#">Data Diri</b-link>
                          </h3>
                        </b-col>
                      </div>
                      <div class="payment-account">
                        <b-col>
                          <h3>
                            <b-link to="/profile/payment-account"
                              >Rekening Bank</b-link
                            >
                          </h3>
                        </b-col>
                      </div>
                      <div class="setting">
                        <b-col>
                          <h3>
                            <b-link to="/setting">
                              <img
                                src="@/assets/img/icons/setting/setting-icon.svg"
                                alt="Setting Icon"
                                class="w-20"
                            /></b-link>
                          </h3>
                        </b-col>
                      </div>
                    </b-row>
                  </div>
                </b-row>
              </div>
              <div class="d-block d-lg-none">
                <b-row>
                  <div class="col-lg-12 col-md-6">
                    <div class="profile-tab">
                      <b-row class="justify-content-center mb-2">
                        <div class="col-lg-12 col-md-6">
                          <b-row class="justify-content-center">
                            <div class="myprofile">
                              <b-col>
                                <h3><b-link to="/profile">Profil</b-link></h3>
                              </b-col>
                            </div>
                          </b-row>
                        </div>
                      </b-row>
                      <b-row class="justify-content-center mb-2">
                        <div class="col-lg-12 col-md-6">
                          <b-row class="justify-content-center">
                            <div class="profile-identity">
                              <b-col>
                                <b-link href="#">Data Diri</b-link>
                              </b-col>
                            </div>
                          </b-row>
                        </div>
                      </b-row>
                      <b-row class="justify-content-center mb-2">
                        <div class="col-lg-12 col-md-6">
                          <b-row class="justify-content-center">
                            <div class="payment-account">
                              <b-col>
                                <b-link to="/profile/payment-account"
                                  >Rekening Bank</b-link
                                >
                              </b-col>
                            </div>
                          </b-row>
                        </div>
                      </b-row>
                      <b-row class="justify-content-center">
                        <div class="col-lg-12 col-md-6">
                          <b-row class="justify-content-center">
                            <div class="setting">
                              <b-col>
                                <b-link to="/setting">
                                  <img
                                    src="@/assets/img/icons/setting/setting-icon.svg"
                                    alt="Setting Icon"
                                    class="w-20"
                                /></b-link>
                              </b-col>
                            </div>
                          </b-row>
                        </div>
                      </b-row>
                    </div>
                  </div>
                </b-row>
              </div>
              <hr />
              <div class="profile-data" v-if="user?.individual != null">
                <!-- <div v-if="user?.individual?.ifua != null">
                  <b-row class="my-2 ml-n4">
                    <div class="edit-data">
                      <b-col class="my-auto mr-1">
                        <b-button
                          href="/profile/change-profile-identity"
                          class="btn-edit-data"
                          style="font-weight: 700; color: #E60012"
                          ><b-icon class="mr-3" icon="pencil"></b-icon>Edit
                          Data</b-button
                        >
                      </b-col>
                    </div>
                  </b-row>
                </div> -->
                <b-row class="py-4">
                  <div class="col-lg-4 col-md-6 my-auto">
                    <p class="title my-auto my-auto">Nama lengkap</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto my-auto">
                      {{
                        user?.individual?.first_name ? user?.individual?.first_name : user?.individual?.ifua_name
                      }}
                    </p>
                  </b-col>
                </b-row>
                <hr />
                <b-row class="py-4">
                  <div class="col-lg-4 col-md-6 my-auto">
                    <p class="title my-auto my-auto">Kewarganegaraan</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto my-auto">
                      {{
                        user?.individual?.country_of_nationality != null
                          ? user?.individual?.country_of_nationality
                          : user?.individual?.country_of_birth
                          ? user?.individual?.country_of_birth
                          : "-"
                      }}
                    </p>
                  </b-col>
                </b-row>
                <hr />
                <b-row class="py-4">
                  <div class="col-lg-4 col-md-6 my-auto">
                    <p class="title my-auto">Dokumen ID</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto">
                      <span
                        style="background: #e5e7eb"
                        class="p-2 rounded font-weight-bold"
                        >{{
                          user?.individual?.identification != null
                            ? user?.individual?.identification
                            : user?.individual?.identity_types
                            ? user?.individual?.identity_types?.name
                            : "-"
                        }}</span
                      >
                      {{
                        user?.individual?.no_id ? user?.individual?.no_id : user?.individual?.id_no
                      }}
                    </p>
                  </b-col>
                </b-row>
                <hr />
                <b-row class="py-4">
                  <div class="col-lg-4 col-md-6">
                    <p class="title my-auto">Tempat & Tanggal Lahir</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto">
                      {{
                        user?.individual?.place_of_birth != null
                          ? user?.individual?.place_of_birth
                          : "-"
                      }},
                      {{
                        user?.individual?.date_of_birth != null
                          ? moment(user?.individual?.date_of_birth).format(
                              " DD MMM YYYY"
                            )
                          : "-"
                      }}
                    </p>
                  </b-col>
                </b-row>
                <hr />
                <b-row class="py-4">
                  <div class="col-lg-4 col-md-6">
                    <p class="title my-auto">Jenis Kelamin</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto">
                      {{
                        user?.individual?.genders?.name === "Male"
                          ? "Laki-Laki"
                          : "Wanita"
                      }}
                    </p>
                  </b-col>
                </b-row>
                <hr />
                <b-row class="py-4">
                  <div class="col-lg-4 col-md-6">
                    <p class="title my-auto">Status pernikahan</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto">
                      {{
                        user?.individual?.marital_status != null
                          ? user?.individual?.marital_status
                          : user?.individual?.marital_statuses
                          ? user?.individual?.marital_statuses?.name
                          : "-"
                      }}
                    </p>
                  </b-col>
                </b-row>
                <hr />
                <b-row class="py-4">
                  <div class="col-lg-4 col-md-6">
                    <p class="title my-auto">Agama</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto">
                      {{
                        user?.individual?.religion != null
                          ? user?.individual?.religion
                          : user?.individual?.religions
                          ? user?.individual?.religions.name
                          : "-"
                      }}
                    </p>
                  </b-col>
                </b-row>

                <hr />
                <b-row class="py-4">
                  <div class="col-lg-4 col-md-6">
                    <p class="title my-auto">Pendidikan terakhir</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto">
                      {{
                        user?.individual?.educational_background != null
                          ? user?.individual?.educational_background
                          : user?.individual?.education_backgrounds
                          ? user?.individual?.education_backgrounds.name
                          : "-"
                      }}
                    </p>
                  </b-col>
                </b-row>
                <hr />
                <b-row class="mt-4">
                  <b-col class="identity-address">
                    <h1>Alamat</h1>
                  </b-col>
                </b-row>

                <b-row class="py-4">
                  <div class="col-lg-4 col-md-6">
                    <p class="title my-auto">Alamat</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto">
                      {{
                        user?.individual?.identity_address != null
                          ? user?.individual?.identity_address
                          : user?.individual?.ktp_address
                          ? user?.individual?.identity_address
                          : "-"
                      }}
                    </p>
                  </b-col>
                </b-row>
                <!-- <hr /> -->
                <!-- <b-row class="py-4">
                    <div class="col-lg-4 col-md-6">
                        <p class="title my-auto">Kota/Kabupaten</p>
                    </div>
                    <b-col class="my-auto">
                        <p class="value my-auto">{{ user?.individual?.correspondence_city_name }}</p>
                    </b-col>
                </b-row> -->
                <!-- <hr>               
                <b-row class="py-4">
                    <div class="col-lg-4 col-md-6">
                        <p class="title my-auto">Propinsi</p>
                    </b-col>
                    <b-col class="my-auto">
                        <p class="value my-auto">Data Not Found</p>
                    </b-col>
                </b-row> -->
                <!-- <b-row class="py-4">
                    <div class="col-lg-4 col-md-6">
                        <p class="title my-auto">Negara</p>
                    </div>
                    <b-col class="my-auto">
                        <p class="value my-auto">{{ user?.individual?.country_of_nationality || user?.individual?.identity_city_code }}</p>
                    </b-col>
                </b-row>
                <hr>                -->
                <!-- <b-row class="py-4">
                  <div class="col-lg-4 col-md-6">
                    <p class="title my-auto">Kode Pos</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto">
                      {{
                        user?.individual?.ktp_postal_code != null
                          ? user?.individual?.ktp_postal_code
                          : user?.individual?.identity_postal_code
                          ? user?.individual?.identity_postal_code
                          : "-"
                      }}
                    </p>
                  </b-col>
                </b-row> -->
                <!-- <hr />
                <b-row class="mt-4">
                  <b-col class="residence-address">
                    <h1>Alamat Domisili</h1>
                  </b-col>
                </b-row> -->

                <!-- <b-row class="mb-2">
                    <b-col class="residence-address-checkbox">
                        <b-form-checkbox
                            id="sharia"
                            v-model="residence_address"
                            name="residence-address"
                            value my-auto="accepted"
                            unchecked-value my-auto="not_accepted"
                        >
                            <h2>Alamat Domisili sama dengan Alamat Sesuai Identitas</h2>
                        </b-form-checkbox>                        
                    </b-col>
                </b-row> -->
                <!-- <hr />
                <b-row class="py-4">
                  <div class="col-lg-4 col-md-6">
                    <p class="title my-auto">Alamat</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto">
                      {{
                        user?.individual?.domicile_address ||
                        user?.individual?.correspondence_address
                          ? user?.individual?.domicile_address
                          : "-"
                      }}
                    </p>
                  </b-col>
                </b-row>
                <hr /> -->
                <!-- <b-row class="py-4">
                    <div class="col-lg-4 col-md-6">
                        <p class="title my-auto">Kota/Kabupaten</p>
                    </div>
                    <b-col class="my-auto">
                        <p class="value my-auto">{{ user?.individual?.correspondence_city_name || user?.individual?.domicile_city_code }}</p>
                    </b-col>
                </b-row> -->
                <!-- <hr>               
                <b-row class="py-4">
                    <div class="col-lg-4 col-md-6">
                        <p class="title my-auto">Propinsi</p>
                    </b-col>
                    <b-col class="my-auto">
                        <p class="value my-auto">Data Not Found</p>
                    </b-col>
                </b-row> -->
                <!-- <hr />
                <b-row class="py-4">
                  <div class="col-lg-4 col-md-6">
                    <p class="title my-auto">Kode Pos</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto">
                      {{
                        user?.individual?.domicile_postal_code ||
                        user?.individual?.correspondence_postal_code
                          ? user?.individual?.domicile_postal_code
                          : "-"
                      }}
                    </p>
                  </b-col>
                </b-row> -->

                <!-- <b-row class="mt-4">
                  <b-col class="residence-address">
                    <h1>Alamat Korespondensi</h1>
                  </b-col>
                </b-row> -->

                <!-- <b-row class="mb-2">
                    <b-col class="residence-address-checkbox">
                        <b-form-checkbox
                            id="sharia"
                            v-model="residence_address"
                            name="residence-address"
                            value my-auto="accepted"
                            unchecked-value my-auto="not_accepted"
                        >
                            <h2>Alamat Domisili sama dengan Alamat Sesuai Identitas</h2>
                        </b-form-checkbox>                        
                    </b-col>
                </b-row> -->
                <!-- <hr />
                <b-row class="py-4">
                  <div class="col-lg-4 col-md-6">
                    <p class="title my-auto">Alamat</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto">
                      {{
                        user?.individual?.correspondence_address ||
                        user?.individual?.correspondence_address
                          ? user?.individual?.correspondence_address
                          : "-"
                      }}
                    </p>
                  </b-col>
                </b-row>
                <hr /> -->
                <!-- <b-row class="py-4">
                    <div class="col-lg-4 col-md-6">
                        <p class="title my-auto">Kota/Kabupaten</p>
                    </div>
                    <b-col class="my-auto">
                        <p class="value my-auto">{{ user?.individual?.correspondence_city_name || user?.individual?.domicile_city_code }}</p>
                    </b-col>
                </b-row> -->
                <!-- <hr>               
                <b-row class="py-4">
                    <div class="col-lg-4 col-md-6">
                        <p class="title my-auto">Propinsi</p>
                    </b-col>
                    <b-col class="my-auto">
                        <p class="value my-auto">Data Not Found</p>
                    </b-col>
                </b-row> -->
                <!-- <hr />
                <b-row class="py-4">
                  <div class="col-lg-4 col-md-6">
                    <p class="title my-auto">Kode Pos</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto">
                      {{
                        user?.individual?.correspondence_postal_code != null
                          ? user?.individual?.correspondence_postal_code
                          : "-"
                      }}
                    </p>
                  </b-col>
                </b-row> -->
              </div>
              <div class="profile-data" v-else>
                <b-row class="py-4">
                  <div class="col-lg-4 col-md-6">
                    <p class="title my-auto">Nama perusahaan</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto">
                      {{ user?.institutional?.company_name }}
                    </p>
                  </b-col>
                </b-row>
                <hr />
                <b-row class="py-4">
                  <div class="col-lg-4 col-md-6">
                    <p class="title my-auto">Tanggal berdiri</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto">
                      {{ user?.institutional?.date_of_establishment }}
                    </p>
                  </b-col>
                </b-row>
                <hr />
                <b-row class="mt-4">
                  <b-col class="identity-address">
                    <h1>Alamat Sesuai Identitas</h1>
                  </b-col>
                </b-row>

                <b-row class="py-4">
                  <div class="col-lg-4 col-md-6">
                    <p class="title my-auto">Alamat</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto">
                      {{ user?.institutional?.company_address }}
                    </p>
                  </b-col>
                </b-row>
                <hr />
                <b-row class="py-4">
                  <div class="col-lg-4 col-md-6">
                    <p class="title my-auto">Kota/Kabupaten</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto">
                      {{ user?.institutional?.company_city_name }}
                    </p>
                  </b-col>
                </b-row>
                <hr />
                <b-row class="py-4">
                  <div class="col-lg-4 col-md-6">
                    <p class="title my-auto">Negara</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto">
                      {{ user?.institutional?.country_of_company }}
                    </p>
                  </b-col>
                </b-row>
                <hr />
                <b-row class="py-4">
                  <div class="col-lg-4 col-md-6">
                    <p class="title my-auto">Kode Pos</p>
                  </div>
                  <b-col class="my-auto">
                    <p class="value my-auto">
                      {{ user?.institutional?.company_postal_code }}
                    </p>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div>
            <PortfolioSidebar />
            <TransactionHistorySidebar />
          </div>
        </div>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/partials/header/Header.vue";
import HeaderBottomProfile from "@/components/partials/header/HeaderBottomProfile.vue";
import CompleteTheProfile from "@/components/partials/profile/CompleteTheProfile.vue";
import PortfolioSidebar from "@/components/partials/profile/PortfolioSidebar.vue";
import TransactionHistorySidebar from "@/components/partials/profile/TransactionHistorySidebar.vue";
import Footer from "@/components/partials/footer/Footer.vue";

export default {
  name: "ProfileIdentity",
  metaInfo: {
    title: "Profil - Data Diri | CGS iTrade Fund",
  },
  components: {
    Header,
    HeaderBottomProfile,
    CompleteTheProfile,
    PortfolioSidebar,
    TransactionHistorySidebar,
    Footer,
  },
  data() {
    return {
      ekyc: {},
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      bearer_token: "bearer_token",
      check: "auth/check",
      user: "auth/user",
    }),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.profile {
  background: #f3f4f6;
}
.profile-container {
  padding-top: 140px;
  padding-bottom: 120px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #4b5563;
}

hr {
  margin: 0 !important;
}

.myprofile {
  margin-left: 10px;
  a {
    padding-left: 40px;
    padding-right: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    text-align: center;
    color: #111827;
    text-decoration: none;
  }
}

.profile-identity {
  border-bottom: 3px solid $secondaryColor;
  a {
    padding-left: 40px;
    padding-right: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    text-align: center;
    color: #111827;
    text-decoration: none;
  }
}

.payment-account {
  a {
    padding-left: 40px;
    padding-right: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    text-align: center;
    color: #111827;
    text-decoration: none;
  }
}
.setting {
  a {
    padding-left: 40px;
    padding-right: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    text-align: center;
    color: #111827;
    text-decoration: none;
  }
}

.profile-data {
  padding: 10px 20px 10px 20px;
  border-radius: 6px;

  .profile-image {
    img {
      border-radius: 6px;
      width: 40px;
    }
  }

  .profile-name {
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #111827;
    }
  }

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
  }
  .value {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #111827;
  }

  .payment-account-profile {
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #4b5563;
      margin-bottom: 0px;
    }
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;

      color: #111827;
    }
  }
  a {
    text-decoration: none;
  }

  .bank-image {
    img {
      width: 60px;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #4b5563;
      margin-bottom: 0px;
    }
  }

  .btn-edit {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
  }
}

@media only screen and (max-width: 768px) {
  p {
    font-size: 14px !important;
  }
  .myprofile {
    margin-left: 0px;
    a {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .profile-identity {
    a {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .payment-account {
    a {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  .setting {
    a {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
</style>
